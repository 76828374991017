.warning__modal{
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
    .warning__modal_title{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #425466;
        margin-bottom: 28px;
        text-align: center;
    }
    .warning__modal_btn-group{
        display: flex;
        justify-content: space-around;

        .btn-group{
            padding: 10px;
            box-sizing: border-box;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #FFFFFF;
            &:hover{
                opacity: .7;
                cursor: pointer;
            }
            &.delete{
                background: #CD201F;
            }
            &.cancel{
                background: #4C6FFF;
            }
        }
    }
}

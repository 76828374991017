@import 'consts';

.card-container {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 24px;
    background: $white;
    border-radius: 16px;
    margin-bottom: 1rem;

    .card {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        //margin-bottom: 0.5rem;
        .card-descr {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
            color: $body_muted;
            margin-bottom: 0.5rem;
        }
        .card-value {
            font-size: 20px;
            line-height: 20px;
            font-weight: 600;
            color: $dark_def;
            letter-spacing: 0.06em;
        }

        .card-img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;
            &.card {
                background-image: url('../img/pay_card.png');
                //background-color: $info_def;
            }
            &.clock {
                background-image: url('../img/clock.png');
                //background-color: $tertiary_def;
            }
            &.hourglass {
                background-image: url('../img/hourglass.png');
                //background-color: $secondary_def;
            }
            &.list-stars {
                background-image: url('../img/list-stars.png');
                //background-color: $success_light;
            }
        }
        &.percentage {
            flex-direction: column;
            margin-bottom: 0.5rem;

            .card-info {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                margin-bottom: 0.5rem;
            }

            .card-descr {
                width: 70%;
            }
            .card-value {
                width: 25%;
                text-align: right;
                font-size: 16px;
                line-height: 23px;
                color: $body_muted;
            }

            .bar {
                width: 100%;
                height: 8px;
                background-color: $secondary_def;
                border-radius: 20px;
                div {
                    height: 8px;
                    z-index: 2;
                    border-radius: 20px;
                    //background-color: $tertiary_def;
                    //$warning_soft
                }
            }

        }
    }
    .button {
        width: 50%;
        white-space: nowrap;
    }

}

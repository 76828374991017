.match__right_block{
    border: 1px solid #EDF2F7;
    background: #FFFFFF;
    height: 100%;
    padding: 28px 20px;
    box-sizing: border-box;
    overflow-y: scroll;
    height: calc(100vh - 131px);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 29%;

    &::-webkit-scrollbar {
          width: 0;
    }

    .right__block_title{
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        .block__title_com{
            display: flex;
            align-items: center;
            .block__title_com_img{
                height: 24px;
                margin: 0 10px;
            }
        }
    }

    .right__block_date{
        padding: 16px 20px 22px 20px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        margin-bottom: 13px;
    }

    .right__block_potok{
        padding: 16px 24px 23px 24px;
        box-sizing: border-box;
        background: #FFFFFF;
        border-radius: 16px;
        margin-bottom: 17px;
        box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
        filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));

        .block__potok_title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;

            .potok__title_name{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 25px;
                color: #16192C;
            }

            .potok__title_btn{
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                color: #4C6FFF;
                transition: 0.2s;

                &:hover{
                    opacity: 0.7;
                    cursor: pointer;
                }
            }
        }
        .block__potok_add{
            display: flex;
            flex-direction: column;

            .select__block{
                margin-bottom: 19px;
            }

            .potok__add_switch{
                display: flex;
                margin-bottom: 19px;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #27272E;
            }

            .potok__add_btn{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 12px 20px;
                background: #66CB9F;
                border-radius: 6px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                color: #FFFFFF;
                margin-bottom: 17px;
                transition: .2s;
                img{
                    margin-right: 8px;
                }
                &:hover{
                    opacity: .7;
                    cursor: pointer;
                }
                &.disablet{
                    opacity: 0.5;
                    cursor: default;
                }
            }
        }
    }

    .right__block_link{
        padding: 14px 20px 17px 20px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        margin-bottom: 17px;

        .block__link_check{
            display: flex;
            align-items: center;
            input{
                width: 20px;
                height: 20px;
            }

            .block__link_check-text{
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                color: #0A1F44;
                margin-left: 10px;
            }
        }
    }

    .right__block_btn{
        padding: 16px 24px;
        background: #4C6FFF;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
        text-align: center;
        transition: 0.2s;
        // width: calc((100% - 250px)*0.34);
        // position: fixed;
        // bottom: 10px;
        // // left: 28px;
        // right: 20px;

        &:hover{
            opacity: 0.7;
            cursor: pointer;
        }
    }
}

.createMatchForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fefefe;
    z-index: 2;
    position: absolute;
    left: 10%;
    top:5%;
    width: 60%;
    margin: 0 auto;

    padding: 20px;
    border-radius: 20px;
    box-shadow: inset 0px -1px 0px #EDF2F7;
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1));

    .club-getters {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        width: 80%;
        margin-bottom: 1rem;
    }

    .club-getter {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 40%;
        position: relative;
        .custom__input-container {
            input {
                font-size: 16px;
                line-height: 16px;
            }
        }
        .select__list {
            top: 3rem;
        }
    }

    .right__block_date {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        width: 40%;
    }
    .actions-group {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
        width: 30%;
    }
}

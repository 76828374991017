@import 'consts';

.customProgressBar {
    background: transparent;
    border-radius: 16px;
    position: absolute;
    top: 0px;
    left: -1px;
    width: 100%;
    z-index: 10;

    .bar {
        width: 100%;
        height: 4px;
        //background-color: $secondary_def;
        // border-radius: 20px;
        div {
            height: 4px;
            z-index: 2;
            border-radius: 20px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            //background-color: $tertiary_def;
            //$warning_soft
        }
        .primary {
            background-color: $primary_def;
        }
        .info {
            background-color: $info_def;
        }
        .warning {
            background-color: $tertiary_def;
        }
        .secondary {
            background-color: $secondary_def;
        }
        .success {
            background-color: $success_light;
        }
    }

}

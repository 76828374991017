.block__stat{
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    .block__stat_name{
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            color: #425466;
        }
    }
    .block__stat_line{
        width: 100%;
        height: 8px;
        background: #E6FDFF;
        border-radius: 5px;
        position: relative;
    }
    .block__stat_line-blur{
        height: 8px;
        background: #68DBF2;
        border-radius: 5px;
        top: 0;
        left: 0;
    }
}

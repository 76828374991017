@import 'consts';

.custom__input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-weight: bold;
    //align-self: center;
    box-sizing: border-box;
    .custom__label {
        margin-bottom: 0.5rem;

        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: $body_light;
        cursor: pointer;
    }

    .input {
        width: 100%;
        padding: 10px 16px;
        font-size: 13px;
        line-height: 16px;
        background: $white;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
        border-radius: 6px;
        border: none;
        cursor: pointer;

    }

}

@import 'consts';

.header{
    max-height: 131px;
    width: 100%;
    border-bottom: 1px solid #EDF2F7;
    padding: 30px 30px 40px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header__text{
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;


        .header__text_title{
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 136.02%;
            color: #27272E;
        }
        .header__group_btn{
            display: flex;

            .group__btn_load{
                padding: 12px 20px;
                box-sizing:border-box;
                border: 1px solid #EDF2F7;
                border-radius: 6px;
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 189px;
                transition: 0.2s;

                &:hover{
                    cursor: pointer;
                    opacity: 0.8;
                }

                span{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    margin-left: 8px;
                    white-space: nowrap;
                }
            }
        }
    }
}

.header__text_tab{
    display: flex;
    margin-bottom: 20px;
    transition: 0.2s;
    position: absolute;
    margin-top: -65px;

    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }

    .text__tab_title{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #718096;
        margin-right: 30px;
    }

    .active{
        position: relative;

        span{
            color: #1A202C
        }

        &::before{
            content: '';
            display: block;
            position: absolute;
            left:0;
            bottom: -15px;
            width:100%;
            height: 1px;
            background: #4C6FFF;

        }
    }

    .custom__input-container {
        position: absolute;
        top: -7px;
        left: 315px;
        //border: 1px solid $dark_def;
        &.clubterm {
            left: 635px;
        }
    }

    &.long {
        .custom__input-container {
            left: 430px;
        }
    }

    &.empty {
        .custom__input-container {
            left: 100px;
        }
    }
}

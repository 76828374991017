.switch{
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #27272E;

    .switch__btn{
        width: 40px;
        height: 22px;
        background: #E4EBF6;
        border-radius: 100px;
        margin: 0 12px 0 16px;
        position: relative;
        transition: all .4s ease-in-out;

        &:hover{
            cursor: pointer;
        }

        &:before{
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
            height: 18px;
            width: 18px;
            background: #FFFFFF;
            border-radius: 50%;
            transition: .7s;
        }
        &.active{
            background: #4C6FFF;
            &:before{
                left:19px;
            }
        }
    }
}

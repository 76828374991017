.user__block{
    padding: 16px;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.24));
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;
    position: relative;

    .user__block_logo{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: red;
        position: relative;
        .user__block_logo-zvezda{
            position: absolute;
            bottom: 0;
            right: -12px;
        }
    }

    .user__block_name{
        display: flex;
        flex-direction: column;

        .block__name_title{
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 25px;
            color: #27272E;
        }

        .block__name_desc{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 23px;
            color: #425466;
        }
    }
    .user__block_btn{
        transition: 0.3s;

        &:hover{
            cursor: pointer;
            opacity: 0.7;
        }
    }
    .user__block_appoint{
        display: flex;
        padding: 10px;
        box-sizing:border-box;
        background: #FFFFFF;
        box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
        border-radius: 8px;
        position: absolute;
        top: 30px;
        left: 30px;
        z-index: 99;


    .block__appoint_add,.block__appoint_delet{
        display: flex;
        padding: 8px;
        box-sizing: border-box;
        align-items: center;
        transition: 0.2s;
        background: #4C6FFF;
        border-radius: 10px;

        &:last-child{
            margin-left: 8px;
            background: #F16063
        }
        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
        span{
            margin-left: 12px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            display: flex;
            align-items: center;
            color:#FFFFFF;
        }
    }
    }
}

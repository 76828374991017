.table{
    width: 100%;
    box-shadow: inset 0px -1px 0px #EDF2F7;
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1));
    box-sizing: border-box;
    background: #FAFAFB;
    border-radius: 12px;



    .table__block{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 800px;
        overflow-y: scroll;
        position: relative;
        height: calc(100vh - 245px);

        &::-webkit-scrollbar{
            width: 0;
        }
        &:nth-child(2){
            margin-top: 50px;
        }

        .table__block_name{
            padding: 18px 24px;
            box-sizing: border-box;
            display: grid;
            grid-template-columns: 20% 15% 18% 35% 12%;
            position: fixed;
            top:0;
            left:0;
            width: 100%;
            background: #FAFAFB;
            z-index: 3;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        .table__name-text{
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #8492A6;
            text-align: center;

        }

        .table__list_block{
            display: grid;
            grid-template-columns: 20% 15% 18% 35% 12%;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #FFFFFF;
            border-bottom: 1px solid #EDF2F7;


            &.activ{
                background: #F7FAFC;
            }

            &:hover{
                cursor: pointer;
                opacity: .7;
            }
        }
    }

    .list__block_title{
        display: flex;
        align-items: center;
        min-width: 151px;
        position: relative;

        .list__block_title-circle{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 12px;
            position: absolute;
            left: -5px;
            top:calc(50% - 3px)

        }
    }

    .list__block_text{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #425466;
        display: flex;
        align-items: center;

        .block__link{
            display: flex;
            flex-direction: column;

            .block__link_title{
                display: flex;
                justify-content: space-between;
                font-style: normal;
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                color: #4C6FFF;
                text-decoration: underline;
                margin-top: 8px;
            }
        }
    }

    .red{
        background: #F16063;
    }

    .green{
        background: #66CB9F;
    }
}

.match-teams {
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
        height: 24px;
        margin: 0;

        &:first-of-type {
            margin-right: 20px;
        }
    }

    span {
        display: block;
        width: calc((100% - 68px)/2);
        box-sizing: border-box;
        padding: 0 8px;

        &:first-child {
            text-align: right;
        }
    }
}

.potoki__right_block{
    background: #FFFFFF;
    padding: 18px 20px 78px 20px;
    box-sizing: border-box;
    border: 1px solid #EDF2F7;
    height: calc(100vh - 131px);
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 34%;

    &::-webkit-scrollbar{
        width: 0;
    }

    .right__block_tab{
        background: #F6F7F9;
        border-radius: 8px;
        padding: 2px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        width: 100%;

        &:hover{
            cursor:pointer;
            opacity: 0.7;
        }

        .block__tab_name1, .block__tab_name2{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #020303;
            transition: 0.2s;
            padding: 10px;
            width: 50%;

            &.activ{
                background: #FFFFFF;
                border-radius: 8px;


            }
        }
    }
    .right__block_add-marker{
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        background: #4C6FFF;
        border-radius: 6px;
        color:#FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s;
        margin-bottom: 16px;

        &:hover{
            cursor:pointer;
            opacity: 0.7;
        }
    }
}

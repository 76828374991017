@import './Warning.scss';
@import './CreateModal.scss';


.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .7);
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    transition: .2s;
    z-index: 9999;

    .modal__content {
        position: relative;
        background-color: #ffffff;
        border-radius: 16px;
        padding: 16;
        box-sizing: border-box;
        box-shadow: inset 0px -1px 0px #16192c;
        max-width: 500px;
        width: 100%;

        @media screen and (max-width:350px) {
            max-width: 310px;
        }

        .modal__content_close {
            position: absolute;
            top: 10px;
            right: 10px;
            transition: .2s;
            width: 15px;

            &:hover {
                cursor: pointer;
                opacity: .8;
            }
        }
    }
}
.modal-visible {
    visibility: visible;
    opacity: 1;
}

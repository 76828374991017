.copi__link{
    margin-bottom: 16px;

    .copi__link_label{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #425466;
        margin-bottom: 8px;
    }

    .copi__link_input{
        background: #FFFFFF;
        border: 1px solid #7895FF;
        box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
        border-radius: 6px;
        padding: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;

        &:hover{
            cursor: pointer;
        }

        .link_input-text{
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            display: flex;
            align-items: center;
            color: #494949;
            margin-left: 12px;
            overflow: hidden;
            width: 100%;

            border: none;
            outline: none;

        }
    }
}

@import 'consts';

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    text-align: center;
    vertical-align:middle;
    height: 44px;
    padding-top: 12px;
    padding-bottom: 12px;
    color: $white;
    font-size: 14px;
    line-height: 16px;
    //width: 154px;
    transition: all .4s ease-in-out;
    border-radius: 6px;
    box-sizing: border-box;
    &:hover {
        color: $white;
    }
    &.long {
        padding: 16px 20px;
        font-size: 16px;
        line-height: 20px;
        height: 52px;
        & > .button--icon {
            width: 20px;
            height: 20px;
        }
    }
    &.medium {
        padding: 12px 22px;
        font-size: 12px;
        line-height: 12px;
        //height: 36px;
        font-weight: 600;
        & > .button--icon {
            width: 12px;
            height: 12px;
        }
    }
    &.small {
        padding: 4px 8px;
        font-size: 10px;
        line-height: 16px;
        height: 24px;
        & > .button--icon {
            margin-right: 3px;
            width: 10px;
            height: 10px;
        }
    }
    &.primary {
        background-color: $primary_def;
    }
    &.createBtn {
        background-color: $primary_def;
    }
    &.notactive {
        background-color: $secondary_def;
        color: $white;
        opacity: 0.5;
        cursor: default;
    }
    &.success {
        background-color: $success_soft;
        color: $success_def;
    }
    &.warn {
        border: 1px solid $tertiary_def;
        color: $white;
        background: none;
        border-radius: 12px;
        width: 240px;
    }

    &.dark {
        background-color: $black;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    }
    &.square {
        border-radius: 8px;
    }
    &.circle {
        border-radius: 22px;
    }
    &.transparent {
        color: $black;
        background: none;
        border-radius: 12px;
        &:hover {
            color: $dark_def;
            background: $success_soft;
        }
    }

    &--icon {
        display: inline-block;
        width: 24px;
        //min-width: 24px;
        height: 24px;
        margin-right: 5px;
        background-repeat: no-repeat;
        //background-color: $black;
        z-index: 2;
    }
}

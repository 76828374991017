.modal__create{
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .label{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #718096;
        margin-bottom: 8px;
    }

    .modal__create_time{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .create_time {
            display: flex;
            gap:20px;
            &> div{
                display: flex;
                flex-direction: column;
                width: 50%;

            }
        }
        .time__block{
            width: 50%;
            padding: 11.5px 17px;
            box-sizing: border-box;
            background: #FFFFFF;
            border: 1px solid #7895FF;
            box-shadow: 0px 0px 0px 2px rgb(76 111 255 / 30%);
            border-radius: 6px;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 13px;
            color: #494949;
            outline:none;
        }

    }

    .modal__create_title{
        margin-bottom: 30px;
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 136.02%;
        color: #27272E;
        text-align: center;
    }

    .modal__create_select{
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        position: relative;

        .select__list{
            top:75px;
            left:0;
        }
    }

    .create__change_name{
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 2px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        margin-bottom: 10px;

        .name__away{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 50%;
            padding: 6px 11px;
            box-sizing: border-box;
            transition: 0.2s;
            &:hover{
                cursor: pointer;
                opacity: 0.7
            }
            &.active{
                background: #E1E8FF;
                border-radius: 8px;
            }

            img{
                width: 30px;
                height: 25px;
                margin-left: 20px;

            }
        }
        .name__home{
            display: flex;
            align-items: center;
            width: 50%;
            padding: 6px 11px;
            box-sizing: border-box;
            transition: 0.2s;
            &:hover{
                cursor: pointer;
                opacity: 0.7
            }
            &.active{
                background: #E1E8FF;
                border-radius: 8px;
            }

            img{
                width: 30px;
                height: 25px;
                margin-right: 20px;
            }
        }
    }

    .create__player_block{
        width: 100%;
        padding: 16px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        gap:20px;

        .player__block{
            width: 44px;
            height: 44px;
            background: #FFFFFF;
            box-shadow: 0px 0px 1px rgb(12 26 75 / 24%), 0px 3px 8px -1px rgb(50 50 71 / 5%);
            border-radius: 6px;
            padding: 5px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:hover{
                cursor:pointer;
                opacity: .7;
            }

            &.active{
                background: #66CB9F;
            }

            .player__block_name{
                font-style: normal;
                font-weight: 600;
                font-size: 9px;
                line-height: 10px;
                text-align: center;
                color: #000000;
                margin-top: 8px;
            }

            .player__block_num{
                position: absolute;
                top:35%;
                left:40%;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: #000000;
            }
        }
    }

    .modal__create_btn-group{
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;

        .btn-group{
            padding: 10px;
            box-sizing: border-box;
            background: #4C6FFF;
            border-radius: 6px;
            color: #FFFFFF;
            transition: 0.2s;

            &:hover{
                cursor: pointer;
                opacity: 0.7
            }

            &.cancel{
                background:  #F16063;
            }
            &.disable{
                opacity: 0.5;
                cursor: default;
            }
        }
    }
}

@import './PotokTable.scss';
@import './RightBlock/RightBlock.scss';
@import './RightBlock/RightBlockMarker.scss';
@import './RightBlock/RightBlockSummary.scss';

.potoki{
    display: grid;
    grid-template-columns: 65% 35%;
    grid-column-gap: 10px;
    height: 100%;


    .potoki__btn{
        padding: 12px 20px;
        box-sizing: border-box;
        background: #4C6FFF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        margin-left: 16px;
        transition: 0.2s;
        position: absolute;
        top: -64px;
        right: 30px;

        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }

        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #FFFFFF;
            margin-left: 8px;
        }
    }

    .potoki__info{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;

        img{
            width: 57px;
        }

        .info__text{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #425466;
            margin-top: 18px;
        }
    }

    .right__block_btn-wrap {
        background: #fff;
        position: fixed;
        right: 0;
        bottom: 0;
        width: calc((100% - 250px)*0.34);
        z-index: 10;
        height: 72px;
        border-top: 1px solid #EDF2F7;
    }

    .right__block_btn{
        padding: 16px;
        position: absolute;
        bottom: 16px;
        left: 16px;
        right: 16px;
        box-sizing: border-box;
        background: #4C6FFF;
        border-radius: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
        text-align: center;
        transition: 0.2s;
        a{
            color: #FFFFFF;
            text-decoration: none;
        }
        &.disablet{
            opacity: 0.4;
            &:hover{
                opacity: 0.4;
                cursor: default;
            }
        }

        &:hover{
            opacity: 0.7;
            cursor: pointer;
        }
        &.markers{
            background: #CD201F;
        }
    }
}

.date-piker{
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
    .date-piker__title{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #425466;
        margin-bottom: 8px;
    }
    .date-piker__input{
        padding: 16px 17px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 15px;
        color: #494949;
        border: 1px solid #7895FF;
        box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
        border-radius: 6px;

    }
}

.navbar{
    width: 100%;
    max-width: 250px;
    padding: 28px 24px;
    box-sizing: border-box;
    border-right: 1px solid #EDF2F7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .navbar__logo{
        margin-bottom: 58.5px;
    }

    .navbar__list{

        &::after{
            content: '';
            display: block;
            width:100%;
            height: 1px;
            background: #EDF2F7;
        }
        .navbar__item{
            display: flex;
            align-items: center;
            height: 24px;
            margin-bottom: 30px;
            transition: 0.2s;


            &:hover{
                cursor: pointer;
                opacity: 0.8;
            }

            .item__img{
                margin-right: 20px;
            }

            .item__title{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #425466;
            }

            .item__notification{
                padding: 2px 10px;
                box-sizing: border-box;
                color:#F16063;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                background: #FFE6E4;
                border-radius: 100px;
                margin-left: auto;
            }
        }

        .activ{
            position: relative;

            .item__title{
                color: #4C6FFF
            }

            &::before{
                content: '';
                display: block;
                width: 2px;
                height: 100%;
                background: #4C6FFF;
                position: absolute;
                top:0;
                left:-24px;
            }

            rect{
                fill: #4C6FFF
            }

            path{
                fill: #4C6FFF
            }


        }
    }
    .setting{
        margin-top: 40px;

        &::after{
            display: none;
        }
    }

    .prompt{
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color:red;
        margin-bottom: 20px;
    }
}

    .account{

        &::after{
            display: none;
        }
    }

    .switch__link{
        display: flex;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #27272E;
        margin-bottom: 30px;
        padding: 20px 0;
        box-sizing: border-box;
        border-top: 1px solid #EDF2F7;
        border-bottom: 1px solid #EDF2F7;

        .switch__btn{
            width: 40px;
            height: 22px;
            background: #E4EBF6;
            border-radius: 100px;
            margin: 0 12px 0 16px;
            position: relative;
            transition: all .4s ease-in-out;

            &:hover{
                cursor: pointer;
            }

            &:before{
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                height: 18px;
                width: 18px;
                background: #FFFFFF;
                border-radius: 50%;
                transition: .7s;
            }
        }

        span {
            font-weight: 600;
            transition: all .4s ease-in-out;
        }

        .switch__btn-active{
            background: #4C6FFF;
            
            &:before{
                left:19px;
            }
            span:first-child {
                opacity: .4;
            }
        }

        span:last-child {
            opacity: .4;
        }

        &.active {
            span:first-child {
                opacity: .4;
            }

            span:last-child {
                opacity: 1;
            }
        }
    }

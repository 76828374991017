@import './MatchRightBlock.scss';
@import './MatchTable.scss';
@import './CraeteMatch.scss';


.match{
    display: grid;
    grid-template-columns: 70% 30%;
    grid-column-gap: 10px;
    height: 100%;


    .match__btn{
        padding: 12px 20px;
        box-sizing: border-box;
        background: #4C6FFF;
        border-radius: 6px;
        display: flex;
        align-items: center;
        margin-left: 16px;
        transition: 0.2s;
        position: absolute;
        top: -86px;
        right: 28px;

        &:hover{
            cursor: pointer;
            opacity: 0.8;
        }

        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #FFFFFF;
            margin-left: 8px;
        }
    }

    .match__list{


        &::-webkit-scrollbar {
            width: 0;
        }


    }

    .match__info{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 200px;
        margin-left: 90px;

        img{
            width: 57px;
        }

        .info__text{
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 23px;
            text-align: center;
            color: #425466;
            margin-top: 18px;
        }
    }
}

.loader,
.loader:after {
    border-radius: 50%;
    width: 10.8em;
    height: 10.8em;
}
.loader {
    margin: 25% auto;
    font-size: 4px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.5em solid rgba(255, 255, 255, 0);
    border-right: 1.5em solid #4C6FFF;
    border-bottom: 1.5em solid #4C6FFF;
    border-left: 1.5em solid #4C6FFF;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
    z-index: 99;
}
.light-loader {
    border-right: 0.5em solid #fff;
    border-bottom: 0.5em solid #fff;
    border-left: 0.5em solid #fff;
}
.dark-loader {
    border-right: 0.5em solid #000;
    border-bottom: 0.5em solid #000;
    border-left: 0.5em solid #000;
}

.errorqr-modal {
    .loader {
        margin: 1em auto;
        font-size: 7px;
    }
}
@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

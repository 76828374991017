.right__block_match-name{
    padding: 17px 27px 8px 27px;
    box-sizing: border-box;
    background: #F7FAFC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;

    .block__match_com{
        display: flex;
        flex-direction: column;
        align-items: center;

        .block__match_com_img{
            height: 34px;
            margin-bottom: 5px;
        }

        span{
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.06em;
            text-transform: uppercase;
            color: #8492A6;
        }
    }

    .block__match_schet{
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 136.02%;
        display: flex;
        align-items: center;
        color: #425466;
    }
}

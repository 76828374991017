.right__block_markers_change{
    display: flex;
    flex-direction: column;

    .markers__change_name{
        padding: 2px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .name__home,{
            display: flex;
            align-items: center;
            width: 50%;
            padding: 6px 11px;
            box-sizing: border-box;
            transition: 0.2s;
            &:hover{
                cursor: pointer;
                opacity: 0.7;
            }
            &.active{
                background: #E1E8FF;
                border-radius: 8px;
            }
            img{
                margin-right: 30px;
            }

        }

        .name__away{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 50%;
            padding: 6px 11px;
            box-sizing: border-box;
            transition: 0.2s;
            &:hover{
                cursor: pointer;
                opacity: 0.7;
            }
            &.active{
                background: #E1E8FF;
                border-radius: 8px;
            }
            img{
                margin-left: 30px;
            }
        }
        img{
            width: 26px;
            height: 21px;
        }
        span{
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #020303;
        }
    }

    .select__block{
        margin-bottom: 13px;

    }

    .select__list{
        top:185px;
        left: 0;
    }

    .markers__change_player{
        padding: 17px 22px;
        box-sizing: border-box;
        background: #F7FAFC;
        border-radius: 8px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 14px;

            .player__block{
            width: 44px;
            height: 44px;
            background: #FFFFFF;
            box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
            border-radius: 6px;
            padding: 3px 5px 5px 5px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            &:hover{
                cursor:pointer;
                opacity: .7;
            }
            &.active{
                background: #66CB9F;
            }
            .player__block_name{
                font-style: normal;
                font-weight: 600;
                font-size: 9px;
                line-height: 10px;
                text-align: center;
                color: #000000;
                margin-top: 8px;

            }
            .player__block_number{
                position: absolute;
                top:30%;
                left:42%;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: #000000;
            }
        }
    }

    .markers_change_check{
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        input{
            width: 20px;
            height: 20px;
            border-radius: 3px;
        }

        span{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #0A1F44;
            margin-left: 10px;
        }
    }
    .markers__change_radio{
        display: flex;
        align-items: center;
        margin-left: 29px;
        margin-bottom: 15px;



        &:hover{
            opacity: 0.7;
            cursor: pointer;
        }

        .radio{
            min-width: 20px;
            height: 20px;
            border: 3px solid #C9CED6;
            border-radius: 75px;
            transition: 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            &.act{
                border: 3px solid #4C6FFF;
            }

            .radio__activ{
                visibility: hidden;
                opacity: 0;
                width: 15px;
                height: 15px;
                border-radius: 75px;
                background: #4C6FFF;
                transition: 0.3s;
                padding: 7px;
                box-sizing: border-box;

                &.activ{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        span{
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #4E5D78;
        }
        .active{
            color: #27272E;
        }
    }

    .markers__change_group-btn{
        display: flex;
        flex-direction: column;
        margin-top: 28px;
        width: 100%;

        .group-btn{
            padding: 16px 24px;
            background: #4C6FFF;
            border-radius: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #FFFFFF;
            text-align: center;
            transition: 0.2s;
            margin-bottom: 7px;

            &:hover{
                opacity: 0.7;
                cursor: pointer;
            }
            &.delete{
                background: #FFEDE3;
                color: #F7936F;
            }
            &.back{
                background: #F7FAFC;
                color: #718096;
                margin-bottom: 0;
            }
        }

    }
}

.right__block_markers{
    padding: 8px 8px 5px 9px;
    box-sizing: border-box;
    background: #F7FAFC;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 7px;
    &:hover{
        opacity: .7;
        cursor:pointer;
    }
    &.green{
        background: #66cb9f;
    }
    &.red{
        background: #F16063;
    }

    .marker__half{
        margin-right: 10px
    }

    .markers__img{
        height: 26px;
        margin-right:22px;
    }
    .markers__time{
        padding: 2px 10px;
        box-sizing: border-box;
        background: #E1E8FF;
        border-radius: 6px;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        color: #4A5568;
        margin-right: 22px;
    }
    .markers__text{
        display: flex;
        flex-direction: column;

        .markers__text_title{
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 11px;
            color: #425466;
            margin-bottom: 4px;
        }
        .markers__text_name{
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 11px;
            color: #718096;
        }

    }
    .markers__number{
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 11px;
        color:  #0A1F44;
        margin-left: 10px;
    }
}

.table__potok{
    width: 100%;
    box-shadow: inset 0px -1px 0px #EDF2F7;
    filter: drop-shadow(0px 0px 1px rgba(12, 26, 75, 0.1));
    border-radius: 12px;
    box-sizing: border-box;
    background: #FAFAFB;
    border-radius: 12px;

    .table__block_name{
        padding: 18px 24px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: 25% 15% 35% 25%;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        color: #8492A6;
        text-align: center;
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        background: #FAFAFB;
        z-index: 3;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .table__block{
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow-y: scroll;
        position: relative;
        margin-top: 50px;
        height: calc(100vh -  245px);
        box-sizing: border-box;

        &::-webkit-scrollbar{
            width: 0;
        }

        .table__item_block{
            display: grid;
            grid-template-columns:  25% 15% 35% 25%;
            padding: 16px 24px;
            box-sizing: border-box;
            background: #FFFFFF;
            border-bottom: 1px solid #EDF2F7;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #425466;


            &.activ{
                background: #F7FAFC;
            }

            &:hover{
                cursor: pointer;
                opacity: .7;
            }

            .list__item_timer{
                display: flex;
                align-items: center;

                .item__timer-circle{
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 12px;

                    &.red{
                        background: #F16063;
                    }

                    &.green{
                        background: #66CB9F;
                    }
                }
            }

            .list__item_canonical{
                display: flex;
                justify-content:center;

                img{
                    height: 24px;
                }
            }

            .list__item_match{
                display: flex;
                align-items: center;
                justify-content: center;

                .item__match_logo{
                    height: 24px;
                }
            }
            .list__item_staff{
                display: flex;
                align-items: center;
                justify-content: space-between;

                img{
                     margin-left: 40px;
                }
            }
        }
    }

    .match-teams {
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
            height: 24px;
            margin: 0;

            &:first-of-type {
                margin-right: 20px;
            }
        }

        span {
            display: block;
            width: calc((100% - 68px)/2);
            box-sizing: border-box;
            padding: 0 8px;

            &:first-child {
                text-align: right;
            }
        }
    }

    .feed-staff {
        position: relative;

        .chat-link {
            position: absolute;
            right: 0;
            transition: all .4s ease-in-out;

            &:hover {
                cursor: pointer;
                opacity: 0.55;
            }
        }
    }
}

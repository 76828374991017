body {
    margin: 0;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
}
#root {
    height: 100%;
    width: 100%;
}
#content {

   width: 100%;
   display: flex;
   // padding: 12px;
   box-sizing: border-box;

   .content__centre{
       display: flex;
       flex-direction: column;
       width: 100%;
   }

   .landing{
       padding: 30px;
       box-sizing: border-box;
       background: #F7FAFC;
       width: 100%;
       height: 100%;
       position: relative;
   }
}

.secondary {
    font-size: 11px;
    color: #8492A6;
}

@import './Navbar.scss';
@import './Header.scss';
@import './Match/Match.scss';
@import './Potok/Potok.scss';
@import './Ui/DatePiker.scss';
@import './Ui/UserBlock.scss';
@import './Ui/CopiLink.scss';
@import './Ui/BlockStat.scss';
@import './Ui/Select.scss';
@import './Ui/Switch.scss';
@import './Modal/index.scss';
@import './Spiner.scss';

$black: #000000;
$white: #ffffff;
$primary_def: #4C6FFF;
$primary_soft: #E1E8FF;
$secondary_def: #E4ECF7;
$secondary_head: #8492A6;
$table_head: #FAFAFB;
$success_def: #66CB9F;
$success_soft: #DEFFEE;
$success_light: #8CDFB3;
$tertiary_def: #FF92AE;
$warning_def: #F7936F;
$warning_soft: #FFEDE3;
$body_light: #425466;
$body_muted: #7A7A9D;
$dark_def: #16192C;
$info_def: #68DBF2;

@import 'consts';

.staffs {
    display: grid;
    grid-template-columns: 55% 45%;
    gap:20px;
    width: 100%;
    padding: 1rem;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    height: 100%;

    .createBtn {
        position: absolute;
        right: 0;
        top: -132px;
    }

    .staffs__list {
        display: flex;
        flex-direction: column;
        box-shadow: inset 0px -1px 0px $dark_def;
        border-radius: 12px;
        height: calc(100vh - 206px);
        box-sizing: border-box;


        .staff {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 8px 18px;
            background-color: $white;
            color: $secondary_head;
            //border-bottom: 1px solid $body_light;
            //box-shadow: inset 0px -1px 0px $secondary_def;
            border-bottom: 1px solid $secondary_def;
            font-size: 13px;
            line-height: 20px;
            box-sizing: border-box;

            &:hover{
                opacity: .7;
                cursor:pointer;
            }
            &.active{
                background: $secondary_def;
            }
            .staff:last-child {
                border: none;
            }
            & > div {
                //width: calc((100% - 248px) / 4);
                width: 70px;
                height: 20px;
                text-align: center;
            }
            .staff__status {
                width: 36px;
                height: 36px;
                border-radius: 8px;
                cursor: pointer;

                &.senior {
                    background-color: $success_soft;
                    color: $success_def;
                    background-image: url('../img/patch-check.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.junior {
                    background-color: $secondary_def;
                    color: $body_muted;
                    background-image: url('../img/patch-question.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.middle {
                    background-color: $warning_soft;
                    color: $warning_def;
                    background-image: url('../img/patch-plus.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
            .staff__info {
                //width: 248px;
                width: calc(100% - 70px*5);
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-content: center;
            }
            .staff__ava {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                color: $white;
                //align-self: center;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.success {
                    background-color: $success_def;
                }
                &.warn {
                    background-color: $warning_def;
                }
                &.secondary {
                    background-color: $secondary_head;
                }
                &.primary {
                    background-color: $primary_def;
                }
            }
            .staff__name {
                cursor: pointer;

                width: calc(100% - 60px);
                text-align: left;
                font-weight: bold;
                //align-self: center;
            }
            .staff__tg {
                width: 16px;
                height: 16px;
                //align-self: center;
                cursor: pointer;

                span {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    background-image: url('../img/message_link.png');
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &.empty {
                    cursor: auto;
                }
            }
            .staff__balance {
                font-weight: bold;
                color: $dark_def;
                letter-spacing: 0.06em;

            }

            &--header {
                border-radius: 0 12px 0 0;
                background-color: $table_head;
                color: $secondary_head;
                font-size: 10px;
                line-height: 16px;
                font-weight: bold;
                letter-spacing: 0.06em;
                cursor: auto;
                & > div {
                    text-transform: uppercase;
                }
                .staff__balance{
                    color: $secondary_head;
                }
                .staff__info {
                    text-align: left;
                }
            }
        }
        .staffs__table{

            overflow-y: scroll;

            &::-webkit-scrollbar{
                width: 0;
            }
        }

    }


    .staff__item {
        display: flex;
        flex-direction: column;
        //justify-content: space-between;

        box-sizing: border-box;
        .staff__header {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
            box-sizing: border-box;
            .staff__desc {
                font-size: 16px;
                line-height: 25px;
                height: 25px;
                width: 60%;
                font-weight: 600;
                color: $dark_def;
                text-align: left;
            }

            .staff__isStaging {
                width: 30%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .switch__link {
                    padding: 0;
                    margin: 0;
                    border: none;
                    margin-top: 0.5rem;
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: 500;
                    .switch__btn {
                        margin-left: 0;
                    }
                }
            }
        }

        .staff_form {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            width: 100%;
            box-sizing: border-box;
            label {
                margin-bottom: 0.5rem;

                font-size: 14px;
                line-height: 17px;
                font-weight: 500;
                color: $body_light

            }
            input, select {
                width: 100%;
                padding: 10px 16px;
                font-size: 13px;
                line-height: 16px;
                background: #FFFFFF;
                /* Input/Default */
                box-sizing: border-box;
                box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
                border-radius: 6px;
                border: none;
            }
            select {
                -webkit-appearance: none;
               -moz-appearance: none;
               appearance: none;       /* Remove default arrow */
               background-image: url('../img/Select arrow.svg');   /* Add custom arrow */
               background-repeat: no-repeat;
               background-position: 95% 50%;
            }
            & > div {
                margin-bottom: 1rem;
            }

            & div {
                display: flex;
                flex-direction: column;
            }
            .staff__social, .staff__rewards {
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
            }
            .staff__reward, .staff__level, .staff__login, .staff__tg{
                width: 48%;
            }
            .button {
                width: 30%;
                align-self: flex-end;
            }

            .staff__btn_group{
                display: flex;
                justify-content: space-around;
                flex-direction: row;
                .btn__group_add, .btn__group_del{
                    padding: 12px 20px;
                    box-sizing: border-box;
                    background: #4C6FFF;
                    border-radius: 6px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 12px;
                    color: #FFFFFF;
                    transition: .2s;

                    &:hover{
                        opacity: .7;
                        cursor: pointer;
                    }
                }
                .btn__group_del{
                    background: #F16063
                }
            }


        }

        .staff__money {
            width: 100%;
            display: flex;
            flex-direction: column;
            box-sizing: border-box;

            .staff__info{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 20px;

                .staff__info_balance {
                    display: flex;
                    flex-direction: column;
                    .info__balance_block {
                        display: flex;
                        flex-direction: column;
                        padding: 16px 24px;
                        box-sizing: border-box;
                        background: #FFFFFF;
                        border-radius: 16px;
                        align-self: flex-start;
                        width: 100%;
                        margin-bottom: 21px;

                        .staff__b {
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: space-between;
                            margin-bottom: 10px;

                            .balance--descr {
                                font-size: 12px;
                                line-height: 16px;
                                font-weight: 600;
                                color: $body_muted;
                                margin-bottom: 4px;
                            }
                            .balance--sum {
                                font-size: 20px;
                                line-height: 20px;
                                font-weight: 600;
                                color: $dark_def;
                                letter-spacing: 0.06em;
                            }

                            .staff__b--card {
                                width: 46px;
                                height: 46px;
                                border-radius: 50%;
                                background-image: url('../img/pay_card.png');
                                background-repeat: no-repeat;
                                background-position: center;
                                background-color: $tertiary_def;
                            }
                        }

                        .staff__balance_group-btn{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .group__btn{
                                padding: 5px 4px;
                                box-sizing: border-box;
                                background: #DEFFEE;
                                border-radius: 6px;
                                font-style: normal;
                                font-weight: 700;
                                font-size: 9px;
                                line-height: 16px;
                                display: flex;
                                align-items: center;
                                color: #66CB9F;
                                &:hover{
                                    cursor: pointer;
                                    opacity: .7;
                                }
                                &.sammary{
                                    background: #4C6FFF;
                                    color:  #FFFFFF;
                                }

                            }
                        }
                    }
                }
            }

            .staff__feedsInfo {
            }
            .staff__payouts {
                width: 100%;
                display: flex;
                flex-direction: column;
                border-radius: 12px;
                background: #FFFFFF;

                .pay {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 14px 8px 14px 21px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #EDF2F7;

                    .pay__reward {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 13px;
                        letter-spacing: 0.06em;
                        text-transform: uppercase;
                        color: #16192C;
                    }
                    .pay__created {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        align-items: center;
                        color: $body_light;
                    }
                    .pay__complete_btn{
                        padding: 4px 10px;
                        box-sizing: border-box;
                        background: #DEFFEE;
                        border-radius: 6px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 16px;
                        color: #66CB9F;
                    }
                }
                .pay:last-child {
                    border: none;
                }
            }
        }

    }
    .staff__empty {
        width: 45%;
        text-align: center;
        align-self: center;
    }
}
.staff__feedsInfo-filter {
    margin-bottom: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    span {
        margin-right: 1rem;
        font-weight: bold;
    }
    select {
        font-size: 16px;
        padding: 2px 5px;
    }
}

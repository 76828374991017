.select__block{
    padding: 11.5px 17px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #7895FF;
    box-shadow: 0px 0px 0px 2px rgba(76, 111, 255, 0.3);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }


    .select__block_name{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #494949;
        border: none;
        outline:none;
        width: 100%;
        background-image: url('../../img/Select arrow.svg');
        background-position: right;
        background-repeat: no-repeat;
        
        &:hover{
            cursor: pointer;
        }
    }
}
.select__list{
    opacity: 0;
    visibility: hidden;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
    border-radius: 8px;
    position: absolute;
    top: 96px;
    left: 25px;
    padding: 14px 27.7px 14px 12px;
    box-sizing: border-box;
    z-index: 99;
    width: 224px;
    height: 140px;
    display: flex;
    flex-direction: column;
    transition: 0.5s;
    overflow-y: scroll;

    &.visible{
        visibility: visible;
        opacity: 1;
    }
    .select__list_item{
        padding: 10px 0 10px 11px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #425466;
        border-radius: 6px;
        margin-bottom: 6px;
        &:hover{
            cursor: pointer;
            opacity: 0.7;
            background: #EDF2F7
        }

    }
}
